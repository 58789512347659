import React, { useEffect, useState } from "react";
import axios from "axios";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
// import Heading from "@tiptap/extension-heading";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Underline from "@tiptap/extension-underline";
// import Gapcursor from "@tiptap/extension-gapcursor";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Typography from "@tiptap/extension-typography";
import FontSize from "tiptap-extension-font-size";
import FontFamily from "@tiptap/extension-font-family";
import Link from "@tiptap/extension-link";
import { SmilieReplacer } from "./SmilieReplacer.ts";
// import Document from "@tiptap/extension-document";
// import Paragraph from "@tiptap/extension-paragraph";
// import Text from "@tiptap/extension-text";
// import pretty from "pretty";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Select,
  MenuItem,
  Button,
  TextField,
  // IconButton,
  // TextField,
  // Grid,
  InputBase,
} from "@mui/material";
import bgColor from "./bgColor.ts";
import CustomDiv from "./CustomDiv.js";
import LabelStyle from "./LabelStyle.js";
import HeroBlock from "./HeroBlock.js";
import PlaylistBlock from "./PlaylistBlock.js";
// import CustomTableCell from "./CustomTableCell.js";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
import FlexBlock from "./FlexBlock.js";
import { appConfig } from "../../config.js";
import webSafeFonts from "./webSafeFonts.js";
import "./rte.css";
import "./styles.css";

const TipTapEditor = ({ block, onContentChange = () => {} }) => {
  // console.log(block);
  const NODEURL = appConfig.NODEURL;
  const theme = useTheme();
  const [isSourceView, setSourceView] = useState(false);
  const [sourceContent, setSourceContent] = useState(""); // For source view content
  const [colorText, setColorText] = useState(false);
  const [heros, setHeros] = useState("");
  const [playlists, setPlaylists] = useState("");
  const [playlist, setPlaylist] = useState("");
  const [cellColor, setCellColor] = useState("#000000");
  const [cellBgColor, setCellBgColor] = useState("#ffffff");
  const [backColor, setBackColor] = useState("#ffffff");
  const [fontSizeAttr, setFontSizeAttr] = useState("rem");
  const [fontSize, setFontSize] = useState("1");
  const [fontFamily, setFontFamily] = useState("fonts...");
  const [title, setTitle] = useState("");
  // Update sourceContent when switching to source view
  const CustomTableCell = TableCell.extend({
    addAttributes() {
      return {
        // extend the existing attributes …
        ...this.parent?.(),

        // and add a new one …
        backgroundColor: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-background-color"),
          renderHTML: (attributes) => {
            return {
              "data-background-color": attributes.backgroundColor,
              style: `background-color: ${attributes.backgroundColor}`,
            };
          },
        },
        color: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-color"),
          renderHTML: (attributes) => {
            return {
              "data-color": attributes.color,
              style: `color: ${attributes.color}`,
            };
          },
        },
      };
    },
  });

  const fetchHeros = async () => {
    try {
      const response = await axios.get(`${appConfig.NODEURL}api/hero`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });

      setHeros(response.data);
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  const fetchPlaylists = async () => {
    try {
      const response = await axios.get(`${appConfig.NODEURL}api/playlists`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });

      setPlaylists(response.data);
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  useEffect(() => {
    setTitle(block.title);
    setBackColor(theme.palette.primary.main);
    setCellBgColor(theme.palette.primary.main);
    setCellColor(theme.palette.primary.contrastText);
    fetchHeros();
    fetchPlaylists();
    return () => {};
  }, []);
  const [selectedHero, setSelectedHero] = useState({ title: "heros..." });
  const [selectedPlaylist, setSelectedPlaylist] = useState({
    name: "playlists...",
  });

  const fetchPlaylist = async () => {
    if (!selectedPlaylist.id) {
      return;
    }
    const resp = await axios.get(
      `${appConfig.NODEURL}api/playlists/${selectedPlaylist.id}`
    );
    console.log(resp.data);
    setPlaylist(resp.data);
  };

  useEffect(() => {
    console.log(selectedPlaylist);
    fetchPlaylist();
  }, [selectedPlaylist]);

  const insertHero = () => {
    const heroMarkup = {
      type: "heroBlock", // This must match the type name in the custom node
      attrs: {
        // imgMarkup: selectedHero.imgmarkup,
        hero: selectedHero,
      },
    };

    // Ensure structured content is passed here
    editor.commands.insertHeroBlock(selectedHero);
  };

  // const insertHero = () => {
  //   editor.commands.insertHeroBlock(selectedHero);
  // };

  const insertFlexBoxes = () => {
    editor.commands.insertFlexBlock();
  };

  const insertPlaylist = () => {
    editor.commands.insertPlaylistBlock(playlist);
  };

  const removePlayList = () => {
    editor.commands.removePlayListBlock(playlist);
  };

  // const toggleSourceView = () => {
  //   if (!isSourceView) {
  //     // Switching to source view: Save editor content to state
  //     setSourceContent(editor.getHTML()); // Or use `editor.getJSON()` for JSON
  //   } else {
  //     // Switching back to editor view: Update editor content with source content
  //     editor.commands.setContent(sourceContent); // Apply the content
  //   }
  //   setSourceView(!isSourceView); // Toggle the view
  // };

  const toggleSourceView = () => {
    if (!isSourceView) {
      // Switching to source view: Save editor content as pretty-printed JSON string
      const jsonContent = editor.getJSON(); // Get JSON content
      setSourceContent(JSON.stringify(jsonContent, null, 2)); // Convert to string
    } else {
      try {
        // Switching back to editor view: Parse the JSON string back to an object
        const parsedContent = JSON.parse(sourceContent); // Convert back to JSON
        editor.commands.setContent(parsedContent); // Set the content
      } catch (error) {
        console.error("Invalid JSON content:", error);
        alert("Invalid JSON content. Please fix any syntax errors.");
      }
    }
    setSourceView(!isSourceView); // Toggle the view
  };

  // const toggleSourceView = () => {
  //   if (!isSourceView) {
  //     // Convert JSON to pretty-printed string for source view
  //     const jsonContent = editor.getJSON();
  //     setSourceContent(JSON.stringify(jsonContent, null, 2));
  //   } else {
  //     try {
  //       // Validate JSON input before applying it to the editor
  //       const parsedContent = JSON.parse(sourceContent);
  //       editor.commands.setContent(parsedContent);
  //     } catch (error) {
  //       console.error(`Invalid JSON content: ${error.message}`, error);
  //       alert("Please fix the JSON syntax errors before switching back.");
  //       return; // Stop if invalid JSON
  //     }
  //   }
  //   setSourceView(!isSourceView);
  // };

  const handleSourceChange = (e) => {
    setSourceContent(e.target.value); // Update the state for the textarea
    // setSourceContent(JSON.stringify(jsonContent, null, 2));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const jsonData = editor.getJSON(); // JSON format for the content column
    const htmlData = editor.getHTML(); // HTML format for the markup column

    // Send data to the server
    fetch(`${NODEURL}api/blocks/${block.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        block_type: block.block_type,
        content: jsonData,
        markup: htmlData,
        position: block.position,
        label: block.label,
        title: title,
      }),
    });
  };

  const toggleBold = () => {
    editor.chain().focus().toggleBold().run();
  };

  const toggleItalic = () => {
    editor.chain().focus().toggleItalic().run();
  };

  const toggleUnderline = () => {
    editor.chain().focus().toggleUnderline().run();
  };

  const applyLabelStyle = () => {
    if (!editor) return;

    const labelStyle = theme.typography.label;

    // Convert theme object to inline style string
    const styleString = Object.entries(labelStyle)
      .map(([key, value]) => `${key}: ${value}`)
      .join("; ");

    // Use setNode to apply the inline style to the current block
    editor.chain().focus().setNode("paragraph", { style: styleString }).run();
  };

  const editor = useEditor({
    extensions: [
      CustomDiv,
      LabelStyle,
      HeroBlock,
      PlaylistBlock,
      FlexBlock,
      bgColor,
      StarterKit,
      Underline,
      TextStyle,
      Color,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      Link.configure({
        openOnClick: true,
        autolink: true,
        defaultProtocol: "https",
      }),
      CustomTableCell,
      TableHeader,
      Typography,
      FontSize,
      FontFamily,
      SmilieReplacer,
    ],
    content: block.content,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      const json = editor.getJSON();
      onContentChange(html, json, title); // Send the updated content back to the parent
    },
  });

  const tableHTML = `
  <table style="width:100%">
    <tr>
      <th>Firstname</th>
      <th>Lastname</th>
      <th>Age</th>
    </tr>
    <tr>
      <td>Jill</td>
      <td>Smith</td>
      <td>50</td>
    </tr>
    <tr>
      <td>Eve</td>
      <td>Jackson</td>
      <td>94</td>
    </tr>
    <tr>
      <td>John</td>
      <td>Doe</td>
      <td>80</td>
    </tr>
  </table>
`;

  const AdvTools = () => {
    return (
      <div style={{ display: "flex", gap: 5 }}>
        <button disabled={!selectedHero.id} onClick={insertHero}>
          inject hero
        </button>
        {heros && (
          <Select
            style={{ height: "1.5em" }}
            size="small"
            value={selectedHero}
            onChange={(e) => setSelectedHero(e.target.value)}
          >
            <MenuItem value={selectedHero}>{selectedHero.title}</MenuItem>
            {heros.map((hero) => {
              return (
                <MenuItem key={hero.id} value={hero}>
                  {hero.title}
                </MenuItem>
              );
            })}
          </Select>
        )}
        <button disabled={!selectedPlaylist.id} onClick={insertPlaylist}>
          inject playlist
        </button>
        {playlists && (
          <Select
            style={{ height: "1.5em" }}
            size="small"
            value={selectedPlaylist}
            onChange={(e) => setSelectedPlaylist(e.target.value)}
          >
            <MenuItem value={selectedPlaylist}>
              {selectedPlaylist.name}
            </MenuItem>
            {playlists.map((list) => {
              return (
                <MenuItem key={list.id} value={list}>
                  {list.name}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </div>
    );
  };

  const clearEditor = () => {
    editor.commands.setContent("");
    setSourceContent({
      type: "doc",
      content: [
        {
          type: "paragraph",
        },
      ],
    });
    const html = editor.getHTML();
    const json = editor.getJSON();
    onContentChange(html, json, title); // Send the updated content back to the parent
  };

  if (!editor) {
    return null;
  }

  //   const toggleSourceView = () => {
  //     setSourceView(!isSourceView);
  //   };

  // Main View
  return (
    <div
      style={{
        backgroundColor: theme.palette.grey[100],
        display: "flex",
        flexDirection: "column",
        gap: 6,
      }}
    >
      <AdvTools />
      <div className="toolbar">
        <button
          onClick={() => editor.commands.unsetAllMarks()}
          // disabled={!editor.can().liftListItem("listItem")}
        >
          clear marks
        </button>
        <button
          style={{
            backgroundColor: editor.isActive("bold") ? "#0a0" : "#ffffff",
          }}
          onClick={toggleBold}
        >
          bold
        </button>
        <button
          style={{
            backgroundColor: editor.isActive("italic") ? "#0a0" : "#ffffff",
          }}
          onClick={toggleItalic}
        >
          italic
        </button>
        <button
          style={{
            backgroundColor: editor.isActive("underline") ? "#0a0" : "#ffffff",
          }}
          onClick={toggleUnderline}
        >
          underline
        </button>

        <button
          onClick={() =>
            editor
              .chain()
              .focus()
              .setFontSize(`${fontSize}${fontSizeAttr}`)
              .run()
          }
        >
          Font Size
        </button>

        <Select
          style={{ height: 20 }}
          size="small"
          onChange={(e) => setFontSize(e.target.value)}
          value={fontSize}
        >
          {[...Array(100).keys()].map((item, index) => {
            return (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>

        <Select
          style={{ height: 20 }}
          size="small"
          onChange={(e) => setFontSizeAttr(e.target.value)}
          value={fontSizeAttr}
        >
          <MenuItem value="rem">rem</MenuItem>
          <MenuItem value="em">em</MenuItem>
          <MenuItem value="pt">pt</MenuItem>
          <MenuItem value="px">px</MenuItem>
        </Select>

        <Select
          style={{ height: 20 }}
          size="small"
          value={fontFamily}
          // onChange={(e) => setFontFamily(e.target.value)}
          onChange={(e) => {
            setFontFamily(e.target.value);
            editor.chain().focus().setFontFamily(e.target.value).run();
          }}
        >
          <MenuItem value="fonts...">fonts...</MenuItem>
          {webSafeFonts.map((font, index) => {
            return (
              <MenuItem key={index} value={font}>
                {font}
              </MenuItem>
            );
          })}
        </Select>
        <button
          onClick={() => editor.chain().focus().setFontFamily(fontFamily).run()}
          style={{ display: fontFamily === "fonts..." ? "none" : "inline" }}
        >
          apply font
        </button>
        <button
          onClick={() => {
            editor.chain().focus().unsetFontFamily().run();
            setFontFamily("fonts...");
          }}
          data-test-id="unsetFontFamily"
          disabled={editor.can().unsetFontFamily()}
        >
          remove font family
        </button>
      </div>
      <div className="toolbar">
        <button
          style={{
            backgroundColor: editor.isActive("heading", { level: 1 })
              ? "#0a0"
              : "#ffffff",
          }}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
        >
          H1
        </button>
        <button
          style={{
            backgroundColor: editor.isActive("heading", { level: 2 })
              ? "#0a0"
              : "#ffffff",
          }}
          s
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
        >
          H2
        </button>
        <button
          style={{
            backgroundColor: editor.isActive("heading", { level: 3 })
              ? "#0a0"
              : "#ffffff",
          }}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
        >
          H3
        </button>

        <button
          style={{
            backgroundColor: editor.isActive("heading", { level: 4 })
              ? "#0a0"
              : "#ffffff",
          }}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
        >
          H4
        </button>

        <button
          style={{
            backgroundColor: editor.isActive("heading", { level: 5 })
              ? "#0a0"
              : "#ffffff",
          }}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }
        >
          H5
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="toolbar">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
              // padding: 1,
              // margin: 1,
            }}
          >
            <input
              type="checkbox"
              onChange={() => setColorText((prev) => !prev)}
              checked={colorText}
            />
            {Object.entries(theme.palette).map((palette, index) => {
              // this filters on just the colors that have main,light,dark:
              if (palette[1].main) {
                return (
                  <div key={index}>
                    <pre style={{ display: "none" }}>
                      {JSON.stringify(palette, null, 3)}
                    </pre>
                    <button
                      style={{
                        color: colorText
                          ? palette[1].main
                          : palette[1].contrastText,
                        backgroundColor: colorText
                          ? palette[1].contrastText
                          : palette[1].main,
                      }}
                      key={index}
                      onClick={() =>
                        editor
                          .chain()
                          .focus()
                          .setColor(
                            colorText
                              ? palette[1].main
                              : palette[1].contrastText
                          )
                          //   .backgroundColor("#000000")
                          .setBackColor(
                            colorText
                              ? palette[1].contrastText
                              : palette[1].main
                          )
                          .run()
                      }
                    >
                      {palette[0]}
                    </button>
                  </div>
                );
              }
            })}
            <button
              onClick={() => editor.commands.unsetAllMarks()}
              // disabled={!editor.can().liftListItem("listItem")}
            >
              clear
            </button>
          </Box>
        </div>
        <div className="toolbar">
          <button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive("bulletList") ? "is-active" : ""}
          >
            Bullets
          </button>
          <button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={editor.isActive("orderedList") ? "is-active" : ""}
          >
            Ordered List
          </button>

          <button
            onClick={() =>
              editor.chain().focus().sinkListItem("listItem").run()
            }
            disabled={!editor.can().sinkListItem("listItem")}
          >
            sink
          </button>
          <button
            onClick={() =>
              editor.chain().focus().liftListItem("listItem").run()
            }
            disabled={!editor.can().liftListItem("listItem")}
          >
            lift
          </button>
        </div>
        <div className="toolbar">
          <button
            onClick={() =>
              editor.chain().focus().insertTable({ rows: 2, cols: 2 }).run()
            }
          >
            Insert table
          </button>
          <button
            style={{ display: "none" }}
            onClick={() =>
              editor
                .chain()
                .focus()
                .insertContent(tableHTML, {
                  parseOptions: {
                    preserveWhitespace: false,
                  },
                })
                .run()
            }
          >
            Insert HTML table
          </button>
          <div
            name="table-group"
            style={{
              display: editor.can().addColumnBefore() ? "flex" : "none",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <button
              onClick={() => editor.chain().focus().addColumnBefore().run()}
              style={{
                display: editor.can().addColumnBefore() ? "inline" : "none",
              }}
            >
              Add column before
            </button>
            <button
              onClick={() => editor.chain().focus().addColumnAfter().run()}
              style={{
                display: editor.can().addColumnAfter() ? "inline" : "none",
              }}
            >
              Add column after
            </button>
            <button
              onClick={() => editor.chain().focus().deleteColumn().run()}
              disabled={!editor.can().deleteColumn()}
            >
              Delete column
            </button>
            <button
              onClick={() => editor.chain().focus().addRowBefore().run()}
              disabled={!editor.can().addRowBefore()}
            >
              Add row before
            </button>
            <button
              onClick={() => editor.chain().focus().addRowAfter().run()}
              disabled={!editor.can().addRowAfter()}
            >
              Add row after
            </button>
            <button
              onClick={() => editor.chain().focus().deleteRow().run()}
              disabled={!editor.can().deleteRow()}
            >
              Delete row
            </button>
            <button
              onClick={() => editor.chain().focus().deleteTable().run()}
              disabled={!editor.can().deleteTable()}
            >
              Delete table
            </button>
            <button
              onClick={() => editor.chain().focus().mergeCells().run()}
              disabled={!editor.can().mergeCells()}
            >
              Merge cells
            </button>
            <button
              onClick={() => editor.chain().focus().splitCell().run()}
              disabled={!editor.can().splitCell()}
            >
              Split cell
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
              disabled={!editor.can().toggleHeaderColumn()}
            >
              ToggleHeaderColumn
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeaderRow().run()}
              disabled={!editor.can().toggleHeaderRow()}
            >
              Toggle header row
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeaderCell().run()}
              disabled={!editor.can().toggleHeaderCell()}
            >
              Toggle header cell
            </button>
            <button
              onClick={() => editor.chain().focus().mergeOrSplit().run()}
              disabled={!editor.can().mergeOrSplit()}
            >
              Merge or split
            </button>
            <input
              list="muiColors"
              value={cellBgColor}
              onChange={(e) => setCellBgColor(e.target.value)}
              type="color"
            />
            <datalist id="muiColors">
              {Object.entries(theme.palette).map((palette, index) => {
                if (!palette[1].main) {
                  return;
                }
                return <option key={index}>{palette[1].main}</option>;
              })}
              {Object.entries(theme.palette).map((palette, index) => {
                if (!palette[1].main) {
                  return;
                }
                return <option key={index}>{palette[1].contrastText}</option>;
              })}
            </datalist>
            <button
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .setCellAttribute("backgroundColor", cellBgColor)
                  .run()
              }
              disabled={
                !editor.can().setCellAttribute("backgroundColor", cellBgColor)
              }
            >
              Background color
            </button>

            <input
              list="muiColors"
              value={cellColor}
              onChange={(e) => setCellColor(e.target.value)}
              type="color"
            />
            <button
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .setCellAttribute("color", cellColor)
                  .run()
              }
              disabled={!editor.can().setCellAttribute("color", cellColor)}
            >
              Text Color
            </button>

            <button
              onClick={() => {
                editor.chain().focus().setCellAttribute("color", "").run();
                editor
                  .chain()
                  .focus()
                  .setCellAttribute("backgroundColor", "")
                  .run();
              }}
            >
              clear cell
            </button>

            <button
              style={{ display: "none" }}
              onClick={() => editor.chain().focus().fixTables().run()}
              disabled={!editor.can().fixTables()}
            >
              Fix tables
            </button>
            <button
              style={{ display: "none" }}
              onClick={() => editor.chain().focus().goToNextCell().run()}
              disabled={!editor.can().goToNextCell()}
            >
              Go to next cell
            </button>
            <button
              style={{ display: "none" }}
              onClick={() => editor.chain().focus().goToPreviousCell().run()}
              disabled={!editor.can().goToPreviousCell()}
            >
              Go to previous cell
            </button>
          </div>
        </div>
        <div className="toolbar">
          <InputBase
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            size="small"
            label="title"
            placeholder="title"
            style={{ height: 20, border: "1px solid black" }}
            required
          ></InputBase>

          <Button
            style={{ ...theme.typography.button2 }}
            onClick={toggleSourceView}
          >
            {isSourceView ? "Editor" : "Source"}
          </Button>
          <ConfirmButtons label="clear content" action={clearEditor} />
          <Button type="submit" sx={{ ...theme.typography.button }}>
            SAVE
          </Button>
        </div>
      </form>
      <div
        style={{
          border: `1px solid ${theme.palette.secondary.dark}`,
          borderRadius: 5,
          margin: 5,
          padding: 0,
        }}
      >
        {!isSourceView ? (
          <EditorContent editor={editor} />
        ) : (
          <textarea
            style={{ width: "100%", height: "400px" }}
            value={sourceContent}
            onChange={handleSourceChange} // Capture changes in source view
          />
        )}
      </div>
    </div>
  );
};

export default TipTapEditor;
