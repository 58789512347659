import React, { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Underline from "@tiptap/extension-underline";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Typography from "@tiptap/extension-typography";
import FontSize from "tiptap-extension-font-size";
import FontFamily from "@tiptap/extension-font-family";
import Link from "@tiptap/extension-link";
import bgColor from "../Rte/bgColor.ts";
import CustomDiv from "../Rte/CustomDiv.js";
import LabelStyle from "../Rte/LabelStyle.js";
import HeroBlock from "../Rte/HeroBlock.js";
import PlaylistBlock from "../Rte/PlaylistBlock.js";
import FlexBlock from "../Rte/FlexBlock.js";
import { SmilieReplacer } from "../Rte/SmilieReplacer.ts";

const ReadOnlyEditor = ({ content }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      CustomDiv,
      LabelStyle,
      HeroBlock,
      PlaylistBlock,
      FlexBlock,
      bgColor,
      Underline,
      TextStyle,
      Color,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      Link.configure({
        openOnClick: true,
        autolink: true,
        defaultProtocol: "https",
      }),
      TableCell,
      TableHeader,
      Typography,
      FontSize,
      FontFamily,
      SmilieReplacer,
    ],
    content,
    editable: false,
  });

  useEffect(() => {
    return () => {
      if (editor) editor.destroy(); // Cleanup editor instance on unmount
    };
  }, [editor]);

  if (!editor) return null; // Wait for the editor to initialize

  return <EditorContent editor={editor} />;
};

export default ReadOnlyEditor;
